import { ChakraProvider, ColorModeScript } from "@chakra-ui/react"
import * as React from "react"
import * as ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "urql"
import { App } from "./App"
import { SettingsContextProvider } from "./context"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import "./styles/global.css"
import theme from "./theme"
import { urqlClient } from "./graphql"

const container = document.getElementById("root")
if (!container) throw new Error("Failed to find the root element")
const root = ReactDOM.createRoot(container)

root.render(
	<React.StrictMode>
		<ColorModeScript />
		<SettingsContextProvider>
			<Provider value={urqlClient}>
				<BrowserRouter>
					<ChakraProvider theme={theme}>
						<App />
					</ChakraProvider>
				</BrowserRouter>
			</Provider>
		</SettingsContextProvider>
	</React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
