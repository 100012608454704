import { UserTypes } from "../graphql"

export const getUserTypeWiseImage = (type: UserTypes): string => {
	switch (type) {
		case UserTypes.BuilderAdmin: {
			return "/illustrations/builder-admin.jpg"
		}

		case UserTypes.Inspector: {
			return "/illustrations/inspector.jpg"
		}

		case UserTypes.Admin: {
			return "/illustrations/admin.jpg"
		}

		default: {
			return "/illustrations/user.jpg"
		}
	}
}
