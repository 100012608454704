import { IssueSeverity } from "../graphql"

export const getIssueSeverityColorScheme = (severity: IssueSeverity) => {
	switch (severity) {
		case IssueSeverity.Cosmetic:
			return "purple"
		case IssueSeverity.Minor:
			return "orange"
		case IssueSeverity.Major:
			return "red"
		default:
			return "purple"
	}
}
