import { SETTINGS_PREFIX_KEY } from "../../constants"
import { initialSettingsState } from "./initial"
import { SettingsAction, SettingsActions, SettingsKeys, SettingsState } from "./types"

export const settingsReducer = (state = initialSettingsState, action: SettingsAction): SettingsState => {
	if (action.type === SettingsActions.SET_VIEW_STYLE) {
		localStorage.setItem(SETTINGS_PREFIX_KEY + SettingsKeys.VIEW_STYLE, action.payload)
		return { ...state, viewStyle: action.payload }
	}

	if (action.type === SettingsActions.RESET) {
		localStorage.setItem(SETTINGS_PREFIX_KEY + SettingsKeys.VIEW_STYLE, initialSettingsState.viewStyle)
		return initialSettingsState
	}

	return state
}
