import { VStack } from "@chakra-ui/react"
import React, { lazy } from "react"
import { BreadcrumbsContextProvider } from "./context"
import { useMeQuery } from "./graphql"

const AuthRouter = lazy(() => import("./router/AuthRouter"))
const UnauthRouter = lazy(() => import("./router/UnauthRouter"))
const SplashPage = lazy(() => import("./pages/common/Splash"))

export const App: React.FC = React.memo(() => {
	const [{ data, fetching }] = useMeQuery()

	if (fetching) {
		return <SplashPage />
	}

	return (
		<VStack w="full" h="full" align="stretch">
			<BreadcrumbsContextProvider>{data?.me ? <AuthRouter user={data.me} /> : <UnauthRouter />}</BreadcrumbsContextProvider>
		</VStack>
	)
})
