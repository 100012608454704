export const colors = {
	primary: {
		"50": "#fff4ef",
		"100": "#fed3bd",
		"200": "#fec2a3",
		"300": "#fdb28a",
		"400": "#fda171",
		"500": "#FC803F",
		"600": "#fb5f0d",
		"700": "#eb5304",
		"800": "#d24a03",
		"900": "#a03802",
	},
	accent: {
		"50": "#ddd1f3",
		"100": "#bea9e9",
		"200": "#af94e4",
		"300": "#a080df",
		"400": "#906cd9",
		"500": "#7243CF",
		"600": "#5a2db2",
		"700": "#50289d",
		"800": "#452389",
		"900": "#311960",
	},
}
