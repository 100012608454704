import { InspectionStatus } from "../graphql"

export const getInspectionStatusColorScheme = (status: InspectionStatus) => {
	switch (status) {
		case InspectionStatus.Pending:
		case InspectionStatus.ReInspectionPending:
			return "yellow"

		case InspectionStatus.Ongoing:
		case InspectionStatus.ReInspectionOngoing:
			return "purple"

		case InspectionStatus.UnderInternalReview:
		case InspectionStatus.UnderReview:
			return "blue"

		case InspectionStatus.Rejected:
			return "red"

		case InspectionStatus.Completed:
			return "green"

		default:
			return "gray"
	}
}
