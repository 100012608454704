import { AreaTypes } from "../graphql"

export const getAreaTypeWiseImage = (type: AreaTypes): string => {
	switch (type) {
		case AreaTypes.Bedroom: {
			return "/illustrations/bedroom.jpg"
		}

		case AreaTypes.Toilet: {
			return "/illustrations/toilet.jpg"
		}

		case AreaTypes.Kitchen: {
			return "/illustrations/kitchen.jpg"
		}

		case AreaTypes.Living: {
			return "/illustrations/living.jpg"
		}

		case AreaTypes.Dining: {
			return "/illustrations/dining.jpg"
		}

		case AreaTypes.Balcony: {
			return "/illustrations/balcony.jpg"
		}

		case AreaTypes.Terrace: {
			return "/illustrations/terrace.jpg"
		}

		case AreaTypes.Basement: {
			return "/illustrations/basement.jpg"
		}

		case AreaTypes.CommonStaircase: {
			return "/illustrations/staircase.jpg"
		}

		case AreaTypes.CommonElevator: {
			return "/illustrations/elevator.jpg"
		}

		case AreaTypes.CommonLobbies: {
			return "/illustrations/lobby.jpg"
		}

		case AreaTypes.CommonParking: {
			return "/illustrations/parking.jpg"
		}

		case AreaTypes.CommonTerrace: {
			return "/illustrations/terrace.jpg"
		}

		case AreaTypes.CommonBasement: {
			return "/illustrations/common-basement.jpg"
		}

		default: {
			return "/illustrations/common-room.jpg"
		}
	}
}
