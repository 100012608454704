import { BreadcrumbsAction, BreadcrumbsActions, BreadcrumbsState } from "./types"

export const initialBreadcrumbsState: BreadcrumbsState = {
	items: [],
}

export const breadcrumbsReducer = (state = initialBreadcrumbsState, action: BreadcrumbsAction) => {
	switch (action.type) {
		case BreadcrumbsActions.SET_BREADCRUMBS: {
			return { ...state, items: action.payload || [] }
		}

		default: {
			return state
		}
	}
}
