import { defineStyleConfig } from "@chakra-ui/react"

export const Button = defineStyleConfig({
	baseStyle: {
		rounded: "full",
	},
	defaultProps: {
		colorScheme: "primary",
	},
})
