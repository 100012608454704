import { nanoid } from "nanoid"

export const dataUrlToFile = (dataUrl: string) =>
	fetch(dataUrl)
		.then(function (res) {
			return res.arrayBuffer()
		})
		.then(function (buf) {
			return new File([buf], nanoid(), { type: "image/jpeg" })
		})
