import { InspectionProgressAction, InspectionProgressActions, InspectionProgressState } from "./types"

export const initialInspectionProgressState: InspectionProgressState = {
	progress: 0,
}

export const inspectionProgressReducer = (state = initialInspectionProgressState, action: InspectionProgressAction): InspectionProgressState => {
	switch (action.type) {
		case InspectionProgressActions.SET_PROGRESS: {
			return { ...state, progress: action.payload || state.progress }
		}

		case InspectionProgressActions.RESET_PROGRESS: {
			return initialInspectionProgressState
		}

		default: {
			return state
		}
	}
}
