import { extendTheme } from "@chakra-ui/react"
import { colors } from "./colors"
import { Button, Link } from "./components"
import { fonts } from "./fonts"

const theme = extendTheme({
	colors,
	components: {
		Button,
		Link,
	},
	fonts,
})

export default theme
