export enum SettingsActions {
	SET_VIEW_STYLE = "SET_VIEW_STYLE",
	RESET = "RESET",
}

export enum SettingsKeys {
	VIEW_STYLE = "VIEW_STYLE",
}

export enum ViewStyles {
	GRID = "GRID",
	LIST = "LIST",
}

export type SettingsState = {
	viewStyle: ViewStyles
}

export type SettingsAction<T = SettingsActions> = T extends SettingsActions.SET_VIEW_STYLE ? { type: T; payload: ViewStyles } : { type: T }
