import React, { PropsWithChildren, useReducer } from "react"
import { SettingsContext } from "./context"
import { initialSettingsState } from "./initial"
import { settingsReducer } from "./reducer"

export const SettingsContextProvider: React.FC<PropsWithChildren> = React.memo(({ children }) => {
	const [state, dispatch] = useReducer(settingsReducer, initialSettingsState)

	return <SettingsContext.Provider value={[state, dispatch]}>{children}</SettingsContext.Provider>
})
