import { Cache, cacheExchange as cE } from "@urql/exchange-graphcache"
import { betterQueryUpdate } from "../../utils"
import {
	AssignBrandItemToAreaMutation,
	AssignBrandItemToAreaMutationVariables,
	AssignMaterialItemToAreaMutation,
	AssignMaterialItemToAreaMutationVariables,
	BlockFragmentDoc,
	BuilderFragmentDoc,
	ClusterFragmentDoc,
	CreateAreaMutation,
	CreateAreaMutationVariables,
	CreateBlockMutation,
	CreateBlockMutationVariables,
	CreateBrandItemsMutation,
	CreateBrandItemsMutationVariables,
	CreateBuilderAdminMutation,
	CreateBuilderAdminMutationVariables,
	CreateBuilderMutation,
	CreateBuilderMutationVariables,
	CreateClusterMutation,
	CreateClusterMutationVariables,
	CreateInspectionMutation,
	CreateInspectionMutationVariables,
	CreateInspectorMutation,
	CreateInspectorMutationVariables,
	CreateMaterialItemsMutation,
	CreateMaterialItemsMutationVariables,
	CreateProjectMutation,
	CreateProjectMutationVariables,
	CreateUnitCategoryMutation,
	CreateUnitCategoryMutationVariables,
	CreateUnitGroupMutation,
	CreateUnitGroupMutationVariables,
	CreateUnitMutation,
	CreateUnitMutationVariables,
	DeleteAreaMutation,
	DeleteAreaMutationVariables,
	DeleteBlockMutation,
	DeleteBlockMutationVariables,
	DeleteBrandItemMutation,
	DeleteBrandItemMutationVariables,
	DeleteBuilderMutation,
	DeleteBuilderMutationVariables,
	DeleteClusterMutation,
	DeleteClusterMutationVariables,
	DeleteElementMutation,
	DeleteElementMutationVariables,
	DeleteInspectionMutation,
	DeleteInspectionMutationVariables,
	DeleteMaterialItemMutation,
	DeleteMaterialItemMutationVariables,
	DeleteProjectMutation,
	DeleteProjectMutationVariables,
	DeleteUnitCategoryMutation,
	DeleteUnitCategoryMutationVariables,
	DeleteUnitGroupMutation,
	DeleteUnitGroupMutationVariables,
	DeleteUnitMutation,
	DeleteUnitMutationVariables,
	InspectionFragmentDoc,
	InspectionsDocument,
	InspectionsQuery,
	InspectionsQueryVariables,
	IssueTemplatesQueryVariables,
	MeDocument,
	MeQuery,
	ProjectFragmentDoc,
	SearchAdminsQueryVariables,
	SearchBlocksOfClusterDocument,
	SearchBlocksOfClusterQuery,
	SearchBlocksOfClusterQueryVariables,
	SearchBrandItemsOfUnitDocument,
	SearchBrandItemsOfUnitQuery,
	SearchBrandItemsOfUnitQueryVariables,
	SearchBuilderAdminsDocument,
	SearchBuilderAdminsQuery,
	SearchBuilderAdminsQueryVariables,
	SearchBuildersDocument,
	SearchBuildersQuery,
	SearchBuildersQueryVariables,
	SearchClustersOfProjectDocument,
	SearchClustersOfProjectQuery,
	SearchClustersOfProjectQueryVariables,
	SearchInspectorsDocument,
	SearchInspectorsQuery,
	SearchInspectorsQueryVariables,
	SearchMaterialItemsOfUnitDocument,
	SearchMaterialItemsOfUnitQuery,
	SearchMaterialItemsOfUnitQueryVariables,
	SearchProjectsOfBuilderDocument,
	SearchProjectsOfBuilderQuery,
	SearchProjectsOfBuilderQueryVariables,
	SearchReportsQueryVariables,
	SearchUnitCategoriesOfProjectDocument,
	SearchUnitCategoriesOfProjectQuery,
	SearchUnitCategoriesOfProjectQueryVariables,
	SearchUnitGroupsOfProjectDocument,
	SearchUnitGroupsOfProjectQuery,
	SearchUnitGroupsOfProjectQueryVariables,
	SearchUnitsOfBlockDocument,
	SearchUnitsOfBlockQuery,
	SearchUnitsOfBlockQueryVariables,
	SigninMutation,
	SigninMutationVariables,
	SigninWithEmailTokenMutation,
	SigninWithEmailTokenMutationVariables,
	SignoutMutation,
	SignoutMutationVariables,
	UnitByIdDocument,
	UnitByIdQuery,
	UnitByIdQueryVariables,
	UnitFragmentDoc,
	UpdateAreaMutation,
	UpdateAreaMutationVariables,
	UpdateBlockMutation,
	UpdateBlockMutationVariables,
	UpdateBrandCheckMutation,
	UpdateBrandCheckMutationVariables,
	UpdateBuilderMutation,
	UpdateBuilderMutationVariables,
	UpdateClusterMutation,
	UpdateClusterMutationVariables,
	UpdateMaterialCheckMutation,
	UpdateMaterialCheckMutationVariables,
	UpdateProjectMutation,
	UpdateProjectMutationVariables,
	UpdateUnitMutation,
	UpdateUnitMutationVariables,
} from "../generated"
import schema from "../generated/schema.json"
import { customPagination } from "../pagination"

export const cacheExchange = cE({
	schema: schema as any,
	keys: {
		Label: () => null,
		Phone: () => null,
		Email: () => null,
		Picture: () => null,
		PictureObject: () => null,
		Dimension: () => null,
		Address: () => null,
		PaginatedInspectionsResponse: () => null,
		PaginatedReportsResponse: () => null,
		ReviewedDimension: () => null,
		Coordinates: () => null,
		UnitAndInspectionStatus: () => null,
		RectDimensions: () => null,
		PaginatedUsersResponse: () => null,
	},
	resolvers: {
		Query: {
			issueTemplates: customPagination("issueTemplates", "PaginatedIssueTemplatesResponse", (args: IssueTemplatesQueryVariables, vars: IssueTemplatesQueryVariables) => args.element === vars.element && args.areaType === vars.areaType),
			inspections: customPagination(
				"inspections",
				"PaginatedInspectionsResponse",
				(args: InspectionsQueryVariables, vars: InspectionsQueryVariables) =>
					args.unitId === vars.unitId &&
					args.status === vars.status &&
					args.builderId === vars.builderId &&
					args.projectId === vars.projectId &&
					args.type === vars.type &&
					(vars.since
						? new Date(vars.since).getTime() === (args.since ? new Date(args.since).getTime() : undefined)
						: args.since
						? new Date(args.since).getTime() === (vars.since ? new Date(vars.since).getTime() : undefined)
						: true) &&
					(vars.until
						? new Date(vars.until).getTime() === (args.until ? new Date(args.until).getTime() : undefined)
						: args.until
						? new Date(args.until).getTime() === (vars.until ? new Date(vars.until).getTime() : undefined)
						: true)
			),
			searchBrandItemsOfUnit: customPagination(
				"brandItems",
				"PaginatedBrandItemsResponse",
				(args: SearchBrandItemsOfUnitQueryVariables, vars: SearchBrandItemsOfUnitQueryVariables) => args.keyword === vars.keyword && args.areaId === vars.areaId && args.unitId === vars.unitId
			),
			searchMaterialItemsOfUnit: customPagination(
				"materialItems",
				"PaginatedMaterialItemsResponse",
				(args: SearchMaterialItemsOfUnitQueryVariables, vars: SearchMaterialItemsOfUnitQueryVariables) => args.keyword === vars.keyword && args.areaId === vars.areaId && args.unitId === vars.unitId
			),
			searchAdmins: customPagination("users", "PaginatedUsersResponse", (args: SearchAdminsQueryVariables, vars: SearchAdminsQueryVariables) => args.keyword === vars.keyword),
			searchBuilders: customPagination("builders", "PaginatedBuildersResponse", (args: SearchBuildersQueryVariables, vars: SearchBuildersQueryVariables) => args.keyword === vars.keyword),
			searchProjectsOfBuilder: customPagination(
				"projects",
				"PaginatedProjectsResponse",
				(args: SearchProjectsOfBuilderQueryVariables, vars: SearchProjectsOfBuilderQueryVariables) => args.keyword === vars.keyword && args.builderId === vars.builderId
			),
			searchClustersOfProject: customPagination(
				"clusters",
				"PaginatedClustersResponse",
				(args: SearchClustersOfProjectQueryVariables, vars: SearchClustersOfProjectQueryVariables) => args.keyword === vars.keyword && args.projectId === vars.projectId
			),
			searchBlocksOfCluster: customPagination(
				"blocks",
				"PaginatedBlocksResponse",
				(args: SearchBlocksOfClusterQueryVariables, vars: SearchBlocksOfClusterQueryVariables) => args.keyword === vars.keyword && args.clusterId === vars.clusterId
			),
			searchInspectors: customPagination("users", "PaginatedUsersResponse", (args: SearchInspectorsQueryVariables, vars: SearchInspectorsQueryVariables) => args.keyword === vars.keyword),
			searchReports: customPagination(
				"reports",
				"PaginatedReportsResponse",
				(args: SearchReportsQueryVariables, vars: SearchReportsQueryVariables) =>
					args.keyword === vars.keyword &&
					args.builderId === vars.builderId &&
					args.inspectorId === vars.inspectorId &&
					args.type === vars.type &&
					(vars.since
						? new Date(vars.since).getTime() === (args.since ? new Date(args.since).getTime() : undefined)
						: args.since
						? new Date(args.since).getTime() === (vars.since ? new Date(vars.since).getTime() : undefined)
						: true) &&
					(vars.until
						? new Date(vars.until).getTime() === (args.until ? new Date(args.until).getTime() : undefined)
						: args.until
						? new Date(args.until).getTime() === (vars.until ? new Date(vars.until).getTime() : undefined)
						: true)
			),
			searchUnitsOfBlock: customPagination("units", "PaginatedUnitsResponse", (args: SearchUnitsOfBlockQueryVariables, vars: SearchUnitsOfBlockQueryVariables) => args.keyword === vars.keyword && args.blockId === vars.blockId),
			searchBuilderAdmins: customPagination("users", "PaginatedUsersResponse", (args: SearchBuilderAdminsQueryVariables, vars: SearchBuilderAdminsQueryVariables) => args.keyword === vars.keyword && args.builderId === vars.builderId),
			searchUnitCategoriesOfProject: customPagination(
				"unitCategories",
				"PaginatedUnitCategoriesResponse",
				(args: SearchUnitCategoriesOfProjectQueryVariables, vars: SearchUnitCategoriesOfProjectQueryVariables) => args.keyword === vars.keyword && args.projectId === vars.projectId
			),
			searchUnitGroupsOfProject: customPagination(
				"unitGroups",
				"PaginatedUnitGroupsResponse",
				(args: SearchUnitGroupsOfProjectQueryVariables, vars: SearchUnitGroupsOfProjectQueryVariables) => args.keyword === vars.keyword && args.projectId === vars.projectId
			),
		},
	},
	updates: {
		Subscription: {},
		Mutation: {
			signin: (_result: SigninMutation, _: SigninMutationVariables, cache: Cache) => {
				betterQueryUpdate<SigninMutation, MeQuery>(cache, { query: MeDocument }, _result, (result, query) => {
					if (result.signin.user) {
						return {
							me: result.signin.user,
						}
					}

					return query
				})
			},
			signinWithEmailToken: (_result: SigninWithEmailTokenMutation, _: SigninWithEmailTokenMutationVariables, cache: Cache) => {
				betterQueryUpdate<SigninWithEmailTokenMutation, MeQuery>(cache, { query: MeDocument }, _result, (result, query) => {
					if (result.signinWithEmailToken.user) {
						return {
							me: result.signinWithEmailToken.user,
						}
					}

					return query
				})
			},
			signout: (result: SignoutMutation, _: SignoutMutationVariables, cache: Cache) => {
				cache.invalidate("Query")
				window.location.replace("/")
			},
			createArea: (_result: CreateAreaMutation, variables: CreateAreaMutationVariables, cache: Cache) => {
				betterQueryUpdate<CreateAreaMutation, UnitByIdQuery>(cache, { query: UnitByIdDocument, variables: { unitId: variables.unitId } as UnitByIdQueryVariables }, _result, (result, query) => {
					if (result.createArea && query.unitById) {
						return {
							...query,
							unitById: {
								...query.unitById,
								areas: [...(query.unitById?.areas || []), result.createArea],
							},
						}
					}

					return query
				})
			},
			createBuilder: (_result: CreateBuilderMutation, _: CreateBuilderMutationVariables, cache: Cache) => {
				betterQueryUpdate<CreateBuilderMutation, SearchBuildersQuery>(
					cache,
					{ query: SearchBuildersDocument, variables: { keyword: "", pagination: { limit: 20, page: 1 } } as SearchBuildersQueryVariables },
					_result,
					(result, query) => {
						if (result.createBuilder.builder && query.searchBuilders) {
							return {
								...query,
								searchBuilders: {
									...query.searchBuilders,
									builders: [result.createBuilder.builder, ...(query.searchBuilders.builders || [])],
								},
							}
						}

						return query
					}
				)
			},
			createInspection: (_result: CreateInspectionMutation, variables: CreateInspectionMutationVariables, cache: Cache) => {
				betterQueryUpdate<CreateInspectionMutation, InspectionsQuery>(
					cache,
					{
						query: InspectionsDocument,
						variables: {
							keyword: "",
							pagination: { limit: 10, page: 1 },

							unitId: variables.unitId,
						} as InspectionsQueryVariables,
					},
					_result,
					(result, query) => {
						if (result.createInspection && query?.inspections) {
							return {
								...query,
								inspections: {
									...query.inspections,
									inspections: [result.createInspection, ...(query.inspections.inspections || [])],
								},
							}
						}

						return query
					}
				)
			},
			createProject: (_result: CreateProjectMutation, variables: CreateProjectMutationVariables, cache: Cache) => {
				betterQueryUpdate<CreateProjectMutation, SearchProjectsOfBuilderQuery>(
					cache,
					{ query: SearchProjectsOfBuilderDocument, variables: { keyword: "", pagination: { limit: 20, page: 1 }, builderId: variables.builderId } as SearchProjectsOfBuilderQueryVariables },
					_result,
					(result, query) => {
						if (result.createProject.project && query?.searchProjectsOfBuilder) {
							return {
								...query,
								searchProjectsOfBuilder: {
									...query.searchProjectsOfBuilder,
									projects: [result.createProject.project, ...(query.searchProjectsOfBuilder.projects || [])],
								},
							}
						}

						return query
					}
				)
			},
			createCluster: (_result: CreateClusterMutation, variables: CreateClusterMutationVariables, cache: Cache) => {
				betterQueryUpdate<CreateClusterMutation, SearchClustersOfProjectQuery>(
					cache,
					{ query: SearchClustersOfProjectDocument, variables: { keyword: "", pagination: { limit: 20, page: 1 }, projectId: variables.projectId } as SearchClustersOfProjectQueryVariables },
					_result,
					(result, query) => {
						if (result.createCluster.cluster && query?.searchClustersOfProject) {
							return {
								...query,
								searchClustersOfProject: {
									...query.searchClustersOfProject,
									clusters: [result.createCluster.cluster, ...(query.searchClustersOfProject.clusters || [])],
								},
							}
						}

						return query
					}
				)
			},
			createBlock: (_result: CreateBlockMutation, variables: CreateBlockMutationVariables, cache: Cache) => {
				betterQueryUpdate<CreateBlockMutation, SearchBlocksOfClusterQuery>(
					cache,
					{ query: SearchBlocksOfClusterDocument, variables: { keyword: "", pagination: { limit: 20, page: 1 }, clusterId: variables.clusterId } as SearchBlocksOfClusterQueryVariables },
					_result,
					(result, query) => {
						if (result.createBlock.block && query?.searchBlocksOfCluster) {
							return {
								...query,
								searchBlocksOfCluster: {
									...query.searchBlocksOfCluster,
									blocks: [result.createBlock.block, ...(query.searchBlocksOfCluster.blocks || [])],
								},
							}
						}

						return query
					}
				)
			},
			createUnit: (_result: CreateUnitMutation, variables: CreateUnitMutationVariables, cache: Cache) => {
				betterQueryUpdate<CreateUnitMutation, SearchUnitsOfBlockQuery>(
					cache,
					{ query: SearchUnitsOfBlockDocument, variables: { keyword: "", pagination: { limit: 20, page: 1 }, blockId: variables.blockId } as SearchUnitsOfBlockQueryVariables },
					_result,
					(result, query) => {
						if (result.createUnit.unit && query?.searchUnitsOfBlock) {
							return {
								...query,
								searchUnitsOfBlock: {
									...query.searchUnitsOfBlock,
									units: [result.createUnit.unit, ...(query.searchUnitsOfBlock.units || [])],
								},
							}
						}

						return query
					}
				)
			},

			createBrandItems: (_result: CreateBrandItemsMutation, variables: CreateBrandItemsMutationVariables, cache: Cache) => {
				betterQueryUpdate<CreateBrandItemsMutation, SearchBrandItemsOfUnitQuery>(
					cache,
					{ query: SearchBrandItemsOfUnitDocument, variables: { keyword: "", pagination: { limit: 20, page: 1 }, unitId: variables.unitId, areaId: variables.areaId } as SearchBrandItemsOfUnitQueryVariables },
					_result,
					(result, query) => {
						if (result.createBrandItems && query?.searchBrandItemsOfUnit) {
							return {
								...query,
								searchBrandItemsOfUnit: {
									...query.searchBrandItemsOfUnit,
									brandItems: [...result.createBrandItems, ...(query.searchBrandItemsOfUnit.brandItems || [])],
								},
							}
						}

						return query
					}
				)
			},
			assignBrandItemToArea: (_result: AssignBrandItemToAreaMutation, variables: AssignBrandItemToAreaMutationVariables, cache: Cache) => {
				betterQueryUpdate<AssignBrandItemToAreaMutation, SearchBrandItemsOfUnitQuery>(
					cache,
					{ query: SearchBrandItemsOfUnitDocument, variables: { keyword: "", pagination: { limit: 20, page: 1 }, unitId: _result?.assignBrandItemToArea?.unitId, areaId: variables.areaId } as SearchBrandItemsOfUnitQueryVariables },
					_result,
					(result, query) => {
						if (result?.assignBrandItemToArea && query?.searchBrandItemsOfUnit) {
							return {
								...query,
								searchBrandItemsOfUnit: {
									...query.searchBrandItemsOfUnit,
									brandItems: [result?.assignBrandItemToArea, ...(query.searchBrandItemsOfUnit.brandItems || [])],
								},
							}
						}

						return query
					}
				)
			},
			createMaterialItems: (_result: CreateMaterialItemsMutation, variables: CreateMaterialItemsMutationVariables, cache: Cache) => {
				betterQueryUpdate<CreateMaterialItemsMutation, SearchMaterialItemsOfUnitQuery>(
					cache,
					{ query: SearchMaterialItemsOfUnitDocument, variables: { keyword: "", pagination: { limit: 20, page: 1 }, unitId: variables.unitId, areaId: variables.areaId } as SearchMaterialItemsOfUnitQueryVariables },
					_result,
					(result, query) => {
						if (result.createMaterialItems && query?.searchMaterialItemsOfUnit) {
							return {
								...query,
								searchMaterialItemsOfUnit: {
									...query.searchMaterialItemsOfUnit,
									materialItems: [...result.createMaterialItems, ...(query.searchMaterialItemsOfUnit.materialItems || [])],
								},
							}
						}

						return query
					}
				)
			},
			assignMaterialItemToArea: (_result: AssignMaterialItemToAreaMutation, variables: AssignMaterialItemToAreaMutationVariables, cache: Cache) => {
				betterQueryUpdate<AssignMaterialItemToAreaMutation, SearchMaterialItemsOfUnitQuery>(
					cache,
					{
						query: SearchMaterialItemsOfUnitDocument,
						variables: { keyword: "", pagination: { limit: 20, page: 1 }, unitId: _result.assignMaterialItemToArea.unitId, areaId: variables.areaId } as SearchMaterialItemsOfUnitQueryVariables,
					},
					_result,
					(result, query) => {
						if (result?.assignMaterialItemToArea && query?.searchMaterialItemsOfUnit) {
							return {
								...query,
								searchMaterialItemsOfUnit: {
									...query.searchMaterialItemsOfUnit,
									materialItems: [result?.assignMaterialItemToArea, ...(query.searchMaterialItemsOfUnit.materialItems || [])],
								},
							}
						}

						return query
					}
				)
			},
			createBuilderAdmin: (_result: CreateBuilderAdminMutation, variables: CreateBuilderAdminMutationVariables, cache: Cache) => {
				betterQueryUpdate<CreateBuilderAdminMutation, SearchBuilderAdminsQuery>(
					cache,
					{ query: SearchBuilderAdminsDocument, variables: { keyword: "", pagination: { limit: 20, page: 1 }, builderId: variables.builderId } as SearchBuilderAdminsQueryVariables },
					_result,
					(result, query) => {
						if (result.createBuilderAdmin.user && query?.searchBuilderAdmins) {
							return {
								...query,
								searchBuilderAdmins: {
									...query.searchBuilderAdmins,
									users: [result.createBuilderAdmin.user, ...(query.searchBuilderAdmins.users || [])],
								},
							}
						}

						return query
					}
				)
			},
			createInspector: (_result: CreateInspectorMutation, _: CreateInspectorMutationVariables, cache: Cache) => {
				betterQueryUpdate<CreateInspectorMutation, SearchInspectorsQuery>(
					cache,
					{ query: SearchInspectorsDocument, variables: { keyword: "", pagination: { limit: 20, page: 1 } } as SearchInspectorsQueryVariables },
					_result,
					(result, query) => {
						if (result.createInspector.user && query.searchInspectors) {
							return {
								...query,
								searchInspectors: {
									...query.searchInspectors,
									users: [result.createInspector.user, ...(query.searchInspectors.users || [])],
								},
							}
						}

						return query
					}
				)
			},
			createUnitCategory: (_result: CreateUnitCategoryMutation, variables: CreateUnitCategoryMutationVariables, cache: Cache) => {
				betterQueryUpdate<CreateUnitCategoryMutation, SearchUnitCategoriesOfProjectQuery>(
					cache,
					{ query: SearchUnitCategoriesOfProjectDocument, variables: { keyword: "", pagination: { limit: 20, page: 1 }, projectId: variables.projectId } as SearchUnitCategoriesOfProjectQueryVariables },
					_result,
					(result, query) => {
						if (result.createUnitCategory.unitCategory && query.searchUnitCategoriesOfProject) {
							return {
								...query,
								searchUnitCategoriesOfProject: {
									...query.searchUnitCategoriesOfProject,
									unitCategories: [result.createUnitCategory.unitCategory, ...(query.searchUnitCategoriesOfProject.unitCategories || [])],
								},
							}
						}

						return query
					}
				)
			},
			createUnitGroup: (_result: CreateUnitGroupMutation, variables: CreateUnitGroupMutationVariables, cache: Cache) => {
				betterQueryUpdate<CreateUnitGroupMutation, SearchUnitGroupsOfProjectQuery>(
					cache,
					{ query: SearchUnitGroupsOfProjectDocument, variables: { keyword: "", pagination: { limit: 20, page: 1 }, projectId: variables.projectId } as SearchUnitGroupsOfProjectQueryVariables },
					_result,
					(result, query) => {
						if (result?.createUnitGroup?.unitGroup && query?.searchUnitGroupsOfProject) {
							return {
								...query,
								searchUnitGroupsOfProject: {
									...query?.searchUnitGroupsOfProject,
									unitGroups: [result?.createUnitGroup?.unitGroup, ...(query?.searchUnitGroupsOfProject?.unitGroups || [])],
								},
							}
						}

						return query
					}
				)
			},

			updateBuilder: (result: UpdateBuilderMutation, _: UpdateBuilderMutationVariables, cache: Cache) => cache.writeFragment(BuilderFragmentDoc, result.updateBuilder),
			updateProject: (result: UpdateProjectMutation, _: UpdateProjectMutationVariables, cache: Cache) => cache.writeFragment(ProjectFragmentDoc, result),
			updateCluster: (result: UpdateClusterMutation, _: UpdateClusterMutationVariables, cache: Cache) => cache.writeFragment(ClusterFragmentDoc, result),
			updateBlock: (result: UpdateBlockMutation, _: UpdateBlockMutationVariables, cache: Cache) => cache.writeFragment(BlockFragmentDoc, result),
			updateUnit: (result: UpdateUnitMutation, _: UpdateUnitMutationVariables, cache: Cache) => cache.writeFragment(UnitFragmentDoc, result),
			deleteBuilder: (result: DeleteBuilderMutation, variables: DeleteBuilderMutationVariables, cache: Cache) => result.deleteBuilder && cache.invalidate({ __typename: "Builder", _id: variables.builderId }),
			deleteProject: (result: DeleteProjectMutation, variables: DeleteProjectMutationVariables, cache: Cache) => result.deleteProject && cache.invalidate({ __typename: "Project", _id: variables.projectId }),
			deleteCluster: (result: DeleteClusterMutation, variables: DeleteClusterMutationVariables, cache: Cache) => result.deleteCluster && cache.invalidate({ __typename: "Cluster", _id: variables.clusterId }),
			deleteBlock: (result: DeleteBlockMutation, variables: DeleteBlockMutationVariables, cache: Cache) => result.deleteBlock && cache.invalidate({ __typename: "Block", _id: variables.blockId }),
			deleteUnit: (result: DeleteUnitMutation, variables: DeleteUnitMutationVariables, cache: Cache) => result.deleteUnit && cache.invalidate({ __typename: "Unit", _id: variables.unitId }),
			deleteBrandItem: (result: DeleteBrandItemMutation, variables: DeleteBrandItemMutationVariables, cache: Cache) => result.deleteBrandItem && cache.invalidate({ __typename: "BrandItem", _id: variables.brandItemId }),
			deleteMaterialItem: (result: DeleteMaterialItemMutation, variables: DeleteMaterialItemMutationVariables, cache: Cache) =>
				result.deleteMaterialItem && cache.invalidate({ __typename: "MaterialItem", _id: variables.materialItemId }),
			deleteUnitCategory: (result: DeleteUnitCategoryMutation, variables: DeleteUnitCategoryMutationVariables, cache: Cache) =>
				result.deleteUnitCategory && cache.invalidate({ __typename: "UnitCategory", _id: variables.unitCategoryId }),
			deleteUnitGroup: (result: DeleteUnitGroupMutation, variables: DeleteUnitGroupMutationVariables, cache: Cache) => result.deleteUnitGroup && cache.invalidate({ __typename: "UnitGroup", _id: variables.unitGroupId }),
			deleteElement: (result: DeleteElementMutation, variables: DeleteElementMutationVariables, cache: Cache) => {
				betterQueryUpdate<DeleteElementMutation, UnitByIdQuery>(
					cache,
					{ query: UnitByIdDocument, variables: { areaId: variables.areaId, element: variables.element, unitId: variables.unitId } as DeleteElementMutationVariables },
					result,
					(result, query) => {
						if (result.deleteElement && query?.unitById) {
							// get the current unit of the deleted element
							const unit = query.unitById

							// remove the deleteElement from the area
							const updatedAreas = [...(unit.areas || [])].map((area) => {
								if (area._id.toString() === variables.areaId) {
									return {
										...area,
										elements: area.elements.filter((e) => e !== variables.element),
									}
								}

								return area
							})

							return {
								...query,
								unitById: {
									...query.unitById,
									areas: [...updatedAreas],
								},
							}
						}

						return query
					}
				)
			},
			deleteInspection: (result: DeleteInspectionMutation, variables: DeleteInspectionMutationVariables, cache: Cache) => result.deleteInspection && cache.invalidate({ __typename: "Inspection", _id: variables.inspectionId }),
			updateArea: (_result: UpdateAreaMutation, variables: UpdateAreaMutationVariables, cache: Cache) => {
				betterQueryUpdate<UpdateAreaMutation, UnitByIdQuery>(cache, { query: UnitByIdDocument, variables: { unitId: variables.unitId } as UnitByIdQueryVariables }, _result, (result, query) => {
					if (result.updateArea && query.unitById) {
						const updatedAreas = [...(query.unitById.areas || [])].map((area) => {
							if (area._id === variables.areaId) {
								return result.updateArea
							}

							return area
						})

						return {
							...query,
							unitById: {
								...query.unitById,
								areas: updatedAreas,
							},
						}
					}

					return query
				})
			},
			deleteArea: (_result: DeleteAreaMutation, variables: DeleteAreaMutationVariables, cache: Cache) => {
				betterQueryUpdate<DeleteAreaMutation, UnitByIdQuery>(cache, { query: UnitByIdDocument, variables: { unitId: variables.unitId } as UnitByIdQueryVariables }, _result, (result, query) => {
					if (result.deleteArea && query.unitById) {
						return {
							...query,
							unitById: {
								...query.unitById,
								areas: [...(query.unitById.areas || [])].filter((s) => s._id !== variables.areaId),
							},
						}
					}

					return query
				})
			},
			updateBrandCheck: (result: UpdateBrandCheckMutation, _: UpdateBrandCheckMutationVariables, cache: Cache) => cache.writeFragment(InspectionFragmentDoc, result),
			updateMaterialCheck: (result: UpdateMaterialCheckMutation, _: UpdateMaterialCheckMutationVariables, cache: Cache) => cache.writeFragment(InspectionFragmentDoc, result),
		},
	},
})
