export enum InspectionProgressActions {
	SET_PROGRESS,
	RESET_PROGRESS,
}

export type InspectionProgressState = {
	progress: number
}

export type InspectionProgressAction = {
	type: InspectionProgressActions
	payload?: number
}
