export enum BreadcrumbsActions {
	SET_BREADCRUMBS = "SET_BREADCRUMBS",
}

export type BreadcrumbItemType = {
	label: string
	path: string
}

export type BreadcrumbsState = {
	items: BreadcrumbItemType[]
}

export type BreadcrumbsAction = {
	type: BreadcrumbsActions
	payload?: BreadcrumbItemType[]
}
